import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useRouter } from 'next/router'
import { FC, ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Button from 'components/Button'
import OverviewIcon from 'assets/svg/app/pie-chart.svg'
import AnalyticsIcon from 'assets/svg/app/bar-graph.svg'
import HistoryIcon from 'assets/svg/app/backup.svg'
import FeesIcon from 'assets/svg/app/fees.svg'
import ClosePanelIcon from 'components/Icons/ClosePanelIcon'
import { DesktopOnlyView, MobileOrTabletView } from 'components/Media'
import NavLink from 'components/Nav/NavLink'
import { TabList, TabPanel } from 'components/Tab'
import TransferSmartMarginModal from 'sections/futures/Trade/TransferSmartMarginModal'
import AppLayout from 'sections/shared/Layout/AppLayout'

import Connector from 'containers/Connector'
import ROUTES from 'constants/routes'
import { setDefaultTab, setOpenModal } from 'state/app/reducer'
import { showDefaultTab, selectShowModal } from 'state/app/selectors'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { LeftSideContent, PageContent } from 'styles/common'
import { selectCurrentTheme } from 'state/preferences/selectors'
import useLocalStorage from 'hooks/useLocalStorage'
import FirstSeenWalletConnectionModal from 'sections/shared/modals/FirstSeenWalletConnectionModal'

const DashboardLayout: FC<{ children?: ReactNode }> = ({ children }) => {
	const { t } = useTranslation()
	const { openConnectModal } = useConnectModal()
	const router = useRouter()
	const { isWalletConnected } = Connector.useContainer()
	const dispatch = useAppDispatch()
	const defaultTab = useSelector(showDefaultTab)
	const openModal = useAppSelector(selectShowModal)
	const currentTheme = useAppSelector(selectCurrentTheme)
	const tabQuery = useMemo(() => router.pathname, [router.pathname])

	const activeTab = tabQuery ?? ROUTES.Portfolio.Home
	const leftMenus = [
		{
			name: ROUTES.Portfolio.Home,
			label: t('dashboard.tabs.overview'),
			active: activeTab === ROUTES.Portfolio.Home,
			href: ROUTES.Portfolio.Home,
			icon: (
				<IconDiv>
					<OverviewIcon />
				</IconDiv>
			),
		},
		{
			name: ROUTES.Portfolio.Markets,
			label: t('dashboard.tabs.markets'),
			active: activeTab === ROUTES.Portfolio.Markets,
			disabled: false,
			href: ROUTES.Portfolio.Markets,
			icon: (
				<IconDiv>
					<AnalyticsIcon />
				</IconDiv>
			),
		},
		{
			name: ROUTES.Portfolio.Fees,
			label: t('dashboard.tabs.fees'),
			active: activeTab === ROUTES.Portfolio.Fees,
			href: ROUTES.Portfolio.Fees,
			icon: (
				<IconDiv>
					<FeesIcon />
				</IconDiv>
			),
		},
		{
			name: ROUTES.Portfolio.History,
			label: t('dashboard.tabs.history'),
			active: activeTab === ROUTES.Portfolio.History,
			href: ROUTES.Portfolio.History,
			icon: (
				<IconDiv>
					<HistoryIcon />
				</IconDiv>
			),
		},
	]

	const [sideMenuOpen, setSideMenuOpen] = useState(true)

	const [seenFlag, setSeenFlag] = useLocalStorage<boolean>('SEEN_FALG', false)
	const [firstVisitModal, setFirstVisitModal] = useState<boolean>(false)

	const openWithdrawModal = () => {
		dispatch(setOpenModal('futures_smart_margin_manage'))
		dispatch(setDefaultTab('withdraw'))
	}

	const openDepositModal = () => {
		dispatch(setOpenModal('futures_smart_margin_manage'))
		dispatch(setDefaultTab('deposit'))
	}

	const openConnect = () => {
		if (seenFlag) {
			openConnectModal?.()
		} else {
			setFirstVisitModal(true)
		}
	}

	const closeFirstVisitModal = () => {
		setFirstVisitModal(false)
	}

	const visitModalConfirmed = () => {
		setSeenFlag(true)
		openConnectModal?.()
		setFirstVisitModal(false)
	}

	return (
		<AppLayout>
			<DesktopOnlyView>
				<PageContent>
					<StyledFullHeightContainer sideMenuOpen={sideMenuOpen}>
						<StyledLeftSideContent sideMenuOpen={sideMenuOpen}>
							<div>
								<StyleShowHideDiv>
									<span>VIEWS</span>
									<StleTexView onClick={() => setSideMenuOpen(false)}>HIDE</StleTexView>
								</StyleShowHideDiv>
								<StyledTabList>
									{leftMenus.map(({ name, label, active, ...rest }) => (
										<NavLink key={name} title={label} isActive={active} {...rest} />
									))}
								</StyledTabList>
							</div>
							<SideBarButtonDiv>
								<WithdrawButtonStyle
									onClick={isWalletConnected ? openWithdrawModal : openConnectModal}
									currentTheme={currentTheme}
								>
									Withdraw
								</WithdrawButtonStyle>
								<ButtonStyle onClick={isWalletConnected ? openDepositModal : openConnect}>
									Deposit
								</ButtonStyle>
							</SideBarButtonDiv>
						</StyledLeftSideContent>
						<StyledLeftSideContentSmall
							onClick={() => setSideMenuOpen(true)}
							sideMenuOpen={sideMenuOpen}
						>
							<StleTexViewIcon>
								<ClosePanelIcon />
							</StleTexViewIcon>
						</StyledLeftSideContentSmall>
						<MainContent>
							<TabPanel name={activeTab} activeTab={activeTab}>
								{children}
							</TabPanel>
						</MainContent>
					</StyledFullHeightContainer>
				</PageContent>
			</DesktopOnlyView>
			{openModal === 'futures_deposit_withdraw_smart_margin' && (
				<TransferSmartMarginModal
					defaultTab={defaultTab as 'deposit' | 'withdraw'}
					onDismiss={() => dispatch(setOpenModal(null))}
				/>
			)}
			{firstVisitModal && (
				<FirstSeenWalletConnectionModal
					onDismiss={closeFirstVisitModal}
					onConfirmed={visitModalConfirmed}
				></FirstSeenWalletConnectionModal>
			)}
			<MobileOrTabletView>{children}</MobileOrTabletView>
		</AppLayout>
	)
}

const StyledTabList = styled(TabList)`
	display: flex;
	flex-direction: column;
	margin-bottom: 4px;
	margin-right: 15px;
`

const MainContent = styled.div`
	overflow-y: scroll;
	scrollbar-width: none;
	margin: 0 auto;
	width: 100%;
	max-width: 1280px;
`

const StyledLeftSideContent = styled(LeftSideContent)<{ sideMenuOpen: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
	opacity: ${(props) => (props.sideMenuOpen ? '1' : '0')};
	transition: width 0.2s ease 0s, opacity 0.2s ease 0s, transform 0.2s ease 0s;
	transform: ${(props) =>
		props.sideMenuOpen ? 'unset' : 'perspective(1000px) translateZ(-200px)'};
	overflow: hidden;
`
const StyledLeftSideContentSmall = styled(LeftSideContent)<{ sideMenuOpen: boolean }>`
	display: flex;
	border-right: ${(props) => props.theme.colors.selectedTheme.border};
	justify-content: center;
	align-items: center;
	cursor: pointer;
	svg {
		path {
			fill: ${(props) => props.theme.colors.selectedTheme.icon.hover};
		}
	}
	&:hover {
		svg {
			path {
				stroke: ${(props) => props.theme.colors.selectedTheme.icon.hover};
			}
			rect {
				stroke: ${(props) => props.theme.colors.selectedTheme.icon.hover};
			}
		}
	}
	opacity: ${(props) => (props.sideMenuOpen ? '0' : '1')};
	transition: width 0.2s ease 0s, opacity 0.2s ease 0s, transform 0.2s ease 0s;
`

const StyledFullHeightContainer = styled.div<{ sideMenuOpen: boolean }>`
	display: grid;
	grid-template-columns: ${(props) => (props.sideMenuOpen ? '306px 0 1fr 0' : '0 50px 1fr 0')};
	height: 100%;
	padding: ${(props) => (props.sideMenuOpen ? '0 14px' : '0')};
	transition-duration: 0.2s;
	transition-property: all;
`

const StyleShowHideDiv = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 16px 18px 12px 10px;
	font-size: 11px;
	line-height: 16px;
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	text-transform: uppercase;
	letter-spacing: 0.6px;
`

const StleTexView = styled.div`
	user-select: none;
	background: ${(props) => props.theme.colors.selectedTheme.newTheme.tabs.position.background};
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	font-size: 11px;
	line-height: 16px;
	margin: -4px -4px 0 0;
	padding: 4px 8px;
	border-radius: 16px;
	cursor: pointer;
	transition: all 0.15s ease-in-out !important;

	&:hover {
		color: ${(props) => props.theme.colors.selectedTheme.button.text.primary};
		background: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	}
`

const StleTexViewIcon = styled.div`
	font-weight: 200;
	width: 20px;
	text-align: center;
	padding: 0px;
	svg {
		path {
			fill: ${(props) => props.theme.colors.selectedTheme.icon.hover};
		}
	}
	&:hover {
		svg {
			path {
				fill: ${(props) => props.theme.colors.selectedTheme.icon.hover};
			}
		}
	}
	border-radius: 10px;
	cursor: pointer;
`
const IconDiv = styled.div`
	width: 1em;
	height: 1em;
	margin-left: -0.25em;
	box-sizing: content-box;
	align-items: center;
	display: flex;
	background-color: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	border-radius: 50%;
	padding: 0.25em;
`
const SideBarButtonDiv = styled.div`
	display: flex;
	gap: 10px;
	padding-right: 12px;
	margin-bottom: 10px;
`
const ButtonStyle = styled(Button)`
	width: 100%;
	height: 40px;
	min-height: 40px;
	max-width: 150px;
	border: none;
	padding: 0px 16px;
`
const WithdrawButtonStyle = styled(Button)<{ currentTheme: string }>`
	width: 100%;
	height: 40px;
	min-height: 40px;
	max-width: 150px;
	padding: 0px 16px;
	border: none;
	background-color: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	color: ${(props) => props.theme.colors.selectedTheme.button.text.primaryWhite};
	&:hover {
		filter: ${(props) => (props.currentTheme === 'dark' ? 'brightness(1.2)' : 'brightness(0.9)')};
		background-color: ${(props) => props.theme.colors.selectedTheme.button.fillHover};
	}
`

export default DashboardLayout
