import Link from 'next/link'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

import LinkIconLight from 'assets/svg/app/link-light.svg'
import { FlexDivRowCentered } from 'components/layout/flex'
import { linkCSS } from 'styles/common'

type NavButtonProps = {
	title: string
	isActive: boolean
	href: string
	icon: any
	disabled?: boolean
	external?: boolean
	children?: ReactNode
}

const NavButton: React.FC<NavButtonProps> = ({
	title,
	href,
	icon,
	external,
	disabled,
	...props
}) => {
	return (
		<div>
			{external ? (
				<StyledLink
					href={href}
					target="_blank"
					rel="noopener noreferrer"
					className={disabled ? 'disabled' : undefined}
					{...props}
				>
					<FlexDivRowCentered columnGap="5px">
						{title} <LinkIconLight />
					</FlexDivRowCentered>
				</StyledLink>
			) : (
				<Link href={href} passHref>
					<StyledLink href={href} className={disabled ? 'disabled' : undefined} {...props}>
						{icon} {title}
					</StyledLink>
				</Link>
			)}
		</div>
	)
}

const StyledLink = styled(Link)<{ isActive: boolean; disabled?: boolean }>`
	${linkCSS};
	display: flex;
	align-items: center;
	gap: 7px;
	width: 100%;
	padding: 6px 12px;
	margin: 2px 0;
	font-family: ${(props) => props.theme.fonts.regular};
	font-size: 15px;
	text-transform: capitalize;
	border-radius: 10px;
	svg {
		path {
			stroke: ${(props) =>
				props.isActive
					? props.theme.colors.selectedTheme.button.text.lightWhite
					: props.theme.colors.selectedTheme.gray};
		}
		rect: ${(props) =>
			props.isActive
				? props.theme.colors.selectedTheme.button.text.lightWhite
				: props.theme.colors.selectedTheme.gray};
	}

	background: ${(props) =>
		props.isActive ? props.theme.colors.selectedTheme.button.fill : 'transparent'};
	color: ${(props) =>
		props.isActive
			? props.theme.colors.selectedTheme.button.text.primary
			: props.theme.colors.selectedTheme.button.text.primaryWhite};
	&:hover {
		background: ${(props) => props.theme.colors.selectedTheme.button.fill};
		color: ${(props) => props.theme.colors.selectedTheme.text.hoverMenuColor};
		svg {
			path {
				stroke: ${(props) => props.theme.colors.selectedTheme.text.hoverMenuColor};
			}
		}
	}

	&.disabled {
		color: ${(props) => props.theme.colors.selectedTheme.button.disabled.text};
		background: transparent;
		pointer-events: none;
	}
`

export default NavButton
